<template>
    <el-container class="el-height summary-con">
        <div class="fl expend" @click="closeSlider=false" v-if="closeSlider"></div>
        <el-aside class="slider" :class="{'closedSlider': closeSlider}">
            <slider @update-month="updateMonth"></slider>
            <div class="close" @click="closeSlider=true" v-if="!closeSlider"></div>
        </el-aside>
        <el-container class="main">
            <el-header class="header clearfix">
                <span class="el_left title">中国联合水泥商混运营情况分析——产销概况</span>
                <div class="el_right btn-group">
                    <el-button
                        class="btn-item"
                        type="primary"
                        size="mini"
                        @click="handelExport"
                    >
                        导出Excel
                    </el-button>
                </div>
            </el-header>
            <el-main class="main-bottom">
                <el-row :gutter="20" class="row">
                    <el-col :span="6">
                        <div class="grid-content">
                            <div class="el_left tit">
                                <span class="iconfont iconchengshi progress"></span>
                                <label>企业上报情况</label>
                            </div>
                            <div class="el_right status progress">
                                {{ fillInfo }}
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="18">
                        <div class="grid-content">
                            <div class="el_left tit">
                                <span class="iconfont iconinfo info font20"></span>
                                <label class="info">注：以下报表中，每个数字可钻取查看每个企业分别上报的数据</label>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <div class="report-forms">
                    <el-container class="el_height container">
                        <el-aside class="slider">
                            <typeList :type-list="typeListData" @changeTable="handelChangeType" :reset-index="resetId"></typeList>
                        </el-aside>
                        <el-main
                            class="edit-table-con"
                            ref="tableWrapper"
                            id="tableWrapper"
                        >
                            <div class="edit-table">
                                <component
                                    :is="zjName"
                                    :load-obj="loading"
                                    v-if="showTable"
                                    :report-data="reportData"
                                    :query-str="queryStr"
                                ></component>
                            </div>
                        </el-main>
                    </el-container>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import slider from './slider';
import typeList from './typeList';
export default {
    components: {
        slider,
        typeList,
    },
    data() {
        return {
            // 产销概况 数据
            typeListData: [
                { status: 1, name: '产销概况', componentName: 'productSaleTable' },
                { status: 1, name: '产品流向', componentName: 'productFlowTable' },
                { status: 1, name: '回款情况', componentName: 'moneyBackTable' },
                { status: 1, name: '资产使用', componentName: 'assetUse' },
                { status: 1, name: '物资采购', componentName: 'materialPurchase' },
                { status: 1, name: '库存情况', componentName: 'stock' },
                { status: 1, name: '应付情况', componentName: 'accountsPayable' },
                { status: 1, name: '混凝土生产成本', componentName: 'productionCosts' },
            ],
            panelObj: { },
            type: 'report',
            reportData: {},
            fillInfo: '',
            oldData: {},
            typeListIndex: 0,
            loading: null,
            showTable: false,
            queryStr: '', // 切换table时 传参
            resetId: '',
            closeSlider: false, // 收起树结构
        };
    },
    computed: {
        // 按类型动态加载表格
        zjName: function () {
            let t_url = '';
            let myComponent = null;
            if (this.panelObj.componentName) {
                t_url = this.panelObj.componentName;
                myComponent = () => import(`./table/${t_url}.vue`);
            }
            return myComponent;
        },
    },
    methods: {
        // 导出Excel
        handelExport() {},
        // 切换类型
        handelChangeType(index) {
            this.panelObj = this.typeListData[index];
            this.getQueryStr();
            // this.queryStr = this.reportData.year + '/' + this.reportData.month + '/' + this.reportData.treeid;
        },
        // 显示加载层
        showLoading() {
            this.loading = this.$loading({
                target: '#tableWrapper',
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.8)',
            });
        },
        // 左侧树切换
        updateMonth(data) {
            this.reportData = data;
            this.getQueryStr();
            const _this = this;
            setTimeout(function () {
                _this.getFillInfo();
            }, 500);
            this.resetId = this.reportData.report_id;
            this.panelObj = this.typeListData[0];
        },
        // 获取填报详情
        getFillInfo() {
            this.$axios
                .get('/interfaceApi/report/instance/fillinfo/yyqk/' + this.queryStr)
                .then(res => {
                    if (res) {
                        this.showTable = true;
                        this.fillInfo = res;
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        getQueryStr() {
            let queryStr = '';
            if (this.reportData.year ) {
                queryStr += this.reportData.year;
            }
            if (this.reportData.month ) {
                queryStr += '/' + this.reportData.month;
            }
            if (this.reportData.treeid !== null) {
                queryStr += '/' + this.reportData.treeid;
            }
            this.queryStr = queryStr;
        },
        // 收起树结构
        handleColseSlider() {
            this.closeSlider = true;
        },
    },
    created() {
        this.panelObj = this.typeListData[0];
        this.zjResData = this.productSaleData;
        const type = this.$queryString(this.$route.meta.TableCode, 'type');
        this.type = type;
    },
};
</script>
<style lang="stylus">
.summary-con
    position relative
    .expend
        position absolute
        top 50%
        left 0
        width 14px
        height 24px
        background url(./../images/icon2_normal.png) no-repeat
        background-size 100% 100%
        cursor pointer
        &:hover
            background url(./../images/icon2_highlight.png) no-repeat
            background-size 100% 100%
    .slider
        width 25%!important
        background #fff
        height auto
        position relative
        transition width 0.8s
        -moz-transition width 0.8s /* Firefox 4 */
        -webkit-transition width 0.8s /* Safari 和 Chrome */
        -o-transition width 0.8s /* Opera */
        .close
            position absolute
            top 50%
            right 0
            width 14px
            height 24px
            background url(./../images/icon1_normal.png) no-repeat
            background-size 100% 100%
            cursor pointer
            &:hover
                background url(./../images/icon1_highlight.png) no-repeat
                background-size 100% 100%
    .closedSlider
        width 0!important
    .main
        margin-left .1rem
        .header
            background #fff
            height .46rem!important
            .title
                line-height .46rem
            .btn-group
                margin-top .08rem
                .btn-item
                    font-size .14rem
                    padding: .07rem .15rem;
        .main-bottom
            background #fff
            margin-top .1rem
            padding .1rem
            padding-right 0
            height: calc(100% - .56rem);
            .row
                width 100%
                .grid-content
                    height .46rem
                    border 1px solid #d7d7d7
                    .progress
                        color #5588f1
                    .time
                        color #EB656F
                    .state
                        color #21BDCC
                    .options
                        color #EB656F
                    .tit
                        line-height .45rem
                        padding-left .22rem
                        font-size: 0.16rem;
                        .iconfont
                            font-size .18rem
                            margin-right .1rem
                        .font18
                            font-size .18rem
                        .font20
                            font-size .2rem
                        .info
                            color #EB656F
                    .status
                        font-size .18rem
                        line-height .45rem
                        padding-right .12rem
            .report-forms
                    height: calc(100% - .57rem);
                    width calc(100% - 0.2rem)
                    margin-top 0.1rem
                    overflow-y auto
                    overflow-x hidden
                    .container
                        overflow-y auto
                        overflow-x hidden
                        position relative
                        .slider
                            width 1.77rem!important
                            height auto
                            overflow visible
                            position absolute
                        .edit-table-con
                            width calc(100% - 1.77rem)
                            height 100%
                            overflow-x auto
                            margin-left 1.77rem
                            padding 0
                            .edit-table
                                padding 0
                                padding 0
                                overflow visible
                                width 100%
                                .wrapper
                                    height auto
                                    .custom-table
                                        border 1px solid #EBEEF5
                                        border-spacing 0px
                                        border-collapse collapse
                                        min-width 100%
                                        .mongth-th
                                            min-width 1rem
                                        .td-right
                                            text-align right
                                            padding-right .3rem
                                        .border6
                                            border-bottom .06rem solid #eef0f7!important
                                        .td-class1
                                            text-align left
                                            padding-left .2rem
                                        .td-class2
                                            text-align left
                                            padding-left .4rem
                                        .td-class3
                                            text-align left
                                            padding-left .6rem
                                        .inner-table
                                            border none
                                            height 100%
                                            tr
                                                border-bottom 1px solid #EBEEF5
                                                td,th
                                                    border none
                                                    white-space nowrap
                                            tr:last-child
                                                border-bottom none
                                        .classify
                                            width:.2rem;
                                            padding: 0 .1rem;
                                        .el-input__inner
                                            width .82rem
                                            height .28rem
                                            line-height .28rem
                                        td,th
                                            border 1px solid #EBEEF5
                                            border-collapse collapse
                                            height .38rem
                                            text-align center
                                            font-size .14rem
                                            box-sizing border-box
                                            white-space nowrap
                                        .padding0
                                            padding 0
                                        .custom-th
                                            // background #F5F7FA
                                            color #022782
                                        .custom-th-bg
                                            background #F5F7FA
                                        .custom-th-odd
                                            background #FDF3F4
                                            color #EB656F
                                        .custom-th-even
                                            background #EEF3FE
                                            color #5588F1
</style>